import { NgClass } from '@angular/common';
import { Component } from '@angular/core';
import { Router, RouterOutlet } from '@angular/router';
import { ENV } from '../../../../src/app/interfaces/interfaces';
import { getEnvironment } from '../../../../src/app/environment/environment';
import { GoogleTagManagerService } from 'angular-google-tag-manager';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [
    RouterOutlet,
    NgClass,
  ],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent {
  env:ENV = getEnvironment();
  
  title = this.env.title;

  constructor(
    public router: Router,
    private gtmService: GoogleTagManagerService,
  ) {
    this.gtmService.addGtmToDom();
  }
}
