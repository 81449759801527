import { DeepPartial, AppConfig, Menu } from "../../../../src/app/interfaces/app-config";

export const projectConfig: DeepPartial<AppConfig> = {
    menu: [
        {
            id: 2,
            path: '/home',
            title: 'Comprar',
        },
        {
            id: 3,
            path: '/tickets-saved',
            title: 'Mis Compras',
        },
    ],
    footerMenu: [
        {
            id: 2,
            // title: 'Redes sociales',
            menu: [
                Menu.create({
                    id: 8,
                    path: 'https://www.facebook.com/nonstopthemadness/?locale=es_LA',
                    // title: 'Facebook',
                    external: true,
                    target: '_blank',
                    icon: 'assets/icons/facebook.svg',
                    iconType: 'img'
                }),
                Menu.create({
                    id: 8,
                    path: 'https://www.instagram.com/nonstopthemadness/',
                    // title: 'Instagram',
                    external: true,
                    target: '_blank',
                    icon: 'assets/icons/instagram.svg',
                    iconType: 'img'
                }),
                Menu.create({
                    id: 9,
                    path: 'https://www.youtube.com/@nonstopthemadness4143',
                    // title: 'YouTube',
                    external: true,
                    target: '_blank',
                    icon: 'assets/icons/youtube.svg',
                    iconType: 'img'
                }),
                Menu.create({
                    id: 10,
                    path: 'https://www.tiktok.com/@nonstopthemadness?lang=es',
                    // title: 'TikTok',
                    external: true,
                    target: '_blank',
                    icon: 'assets/icons/tiktok.svg',
                    iconType: 'img'
                }),
            ]
        }
    ],
    pages: {
        login: {
            title: 'Login',
        }
    },
    swiperBanner: [
        {
            desktop: 'assets/img/general/banners/banner-web.jpg',
            mobile: 'assets/img/general/banners/banner-mobile.jpg'
        },
        // {
        //     desktop: 'assets/img/general/1banner.jpg',
        //     mobile: 'assets/img/general/BannerEvento400x400.jpg'
        // }
    ],
    swiperBannerSuccess: [
        {
            desktop: 'assets/img/general/banners/banner-web.jpg',
            mobile: 'assets/img/general/banners/banner-mobile.jpg'
        }
    ],
};


import { Injector } from "@angular/core";
import { EncryptionService } from "../../../../src/app/services/encryption.service";
import { environment } from "../environments/environment";

const injector = Injector.create({providers: [{provide: EncryptionService, useClass: EncryptionService, deps: []}]});
const encryptionService = injector.get(EncryptionService);

export function projectEncryptData(data: any) {
    const encryptedConfig = encryptionService.encrypt(data, environment.keyEncript, environment.ivEncript);
    return encryptedConfig;
}
